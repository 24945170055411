/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { useArchiveData, useEase } from "hooks";
import { SEO } from "components";
import { Link } from "gatsby";
import sanityClient from "@sanity/client";
import sanityImageUrl from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./archive.module.scss";

const client = sanityClient({
  dataset: "production",
  projectId: "jcmgoa34",
  useCdn: true,
});

const builder = sanityImageUrl(client);

const WritingsArchivePage: React.FC = () => {
  const { archiveData, archiveYears } = useArchiveData();

  const writingsData = {};

  archiveYears.forEach((year) => {
    const writings = archiveData[year].filter(
      (item) => item.type === "writing"
    );

    if (writings.length) {
      writingsData[year] = writings;
    }
  });

  const writingsYears = Object.keys(writingsData).reverse();

  const { outQuad } = useEase();
  const getDelay = (a: number, b: number): string =>
    `${150 + 400 * outQuad(a) + 125 * outQuad(b)}ms`;

  return (
    <main className={styles.main}>
      <SEO title="Writings" />
      <ol className={styles.yearList}>
        {writingsYears.map((year, yearIndex) => (
          <li key={year} className={styles.yearListItem}>
            <h1 className={styles.yearLabel}>{year}</h1>
            <ol className={styles.archiveList}>
              {writingsData[year].map((archiveItem, itemIndex) => (
                <li
                  key={archiveItem.id}
                  className={styles.archiveListItem}
                  style={{
                    animationDelay: getDelay(
                      yearIndex / writingsYears.length,
                      itemIndex / writingsData[year].length
                    ),
                  }}
                >
                  <p className={styles.itemType}>Writing</p>
                  <Link
                    className={styles.itemLink}
                    to={`/${archiveItem.type}/${
                      archiveItem.information!.slug!.current
                    }`}
                  >
                    <h2 className={styles.itemTitle}>
                      {archiveItem.information!.title}
                    </h2>
                  </Link>
                  {archiveItem.information!.shortDescription && (
                    <BlockContent
                      className={styles.itemDescription}
                      blocks={archiveItem.information!.shortDescription}
                      dataset="production"
                      projectId="jcmgoa34"
                      renderContainerOnSingleChild={true}
                      serializers={{ container: "section" }}
                    />
                  )}
                  {archiveItem.information!.tags && (
                    <ul className={styles.tagList}>
                      {archiveItem.information!.tags.map((tag) => (
                        <li
                          key={`${archiveItem.id}-${tag!.slug!.current}`}
                          className={styles.tagListItem}
                        >
                          {tag!.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </main>
  );
};

export default WritingsArchivePage;
